import React from "react";

interface ButtonProps {
  onClick: () => void;
  label?: string;
  children?: any | JSX.Element;
}

const Button = (props: ButtonProps) => {
  const { onClick, label, children } = props;
  const theme = "classic";
  const colorHandler = {
    backgroundColor: () => {
      if (theme === "classic") {
        return "bg-[#0459e0]";
      } else if (theme === "darkgray") {
        return "bg-sky-500";
      }
    },
  };
  return (
    <button
      style={{ borderRadius: "5px" }}
      onClick={() => onClick()}
      className={`${colorHandler.backgroundColor()} text-white px-4 py-2 font-semibold  shadow-md cursor-pointer text-sm`}
    >
      {label ? label : children}
    </button>
  );
};

export default Button;
