import reactLogo from "../assets/react.png";
import flutterLogo from "../assets/flutter.png";
import reduxLogo from "../assets/redux.png";
import webpackLogo from "../assets/webpack.png";

export const json = [
  {
    type: "mcq",
    questionNo: 1,
    question: "Choose the functionality of setState?",
    options: [
      "a. Access the previous state before the setState operation",
      "b. Invoke code after the setState operation is done",
      "c. replace this date completely instead of the default merge action one of the above ",
      "d. None of the above",
    ],
    answer: null,
  },
  {
    type: "mcq-response",
    questionNo: 2,
    question: "Select the react-hooks in following list",
    options: ["a. useState", "b. useEffect", "c. useReact", "d. useRef"],
    answer: null,
  },
  {
    type: "blank",
    questionNo: 3,
    question: "React JS is (blank) of javascript framework",
    answer: null,
  },
  {
    type: "blackWithDropDown",
    questionNo: 4,
    question: "React is invented by  (blank)",
    options: [
      { name: "Facebook", value: "Facebook" },
      { name: "Instagram", value: "Instagram" },
      { name: "Google", value: "Instagram" },
      { name: "Yahoo", value: "Yahoo" },
    ],
    answer: null,
  },
  {
    type: "bool",
    questionNo: 5,
    question: "React-Native is web-development technology?",
    options: ["a. True", "b. False"],
    answer: null,
  },
  {
    type: "drag_and_drop",
    questionNo: 6,
    question: "Which of the following global state management",
    options: [
      { name: "Redux", value: "Redux" },
      { name: "Webpack", value: "Webpack" },
      { name: "Tailwind", value: "Tailwind" },
      { name: "Material UI", value: "Material UI" },
    ],
    answer: null,
  },
  {
    type: "drag_and_drop_image",
    questionNo: 6,
    question: "Which of the following is webpack logo",
    options: [
      { name: "Redux", value: reduxLogo },
      { name: "Webpack", value: webpackLogo },
      { name: "Flutter", value: flutterLogo },
      { name: "React", value: reactLogo },
    ],
    answer: null,
  },
];
