import React, { useEffect, useState } from "react";

const Header = () => {
  const [time, setTime] = useState({ hours: 0, minutes: 1, seconds: 0 });

  const theme = "classic";
  useEffect(() => {
    const interval = setInterval(() => {
      setTime((timer) => {
        const { hours, minutes, seconds } = timer;
        if (seconds > 0) {
          return { ...timer, seconds: seconds - 1 };
        }
        if (minutes === 0 && hours === 0) {
          clearInterval(interval);
          return timer;
        }
        if (minutes === 0) {
          return { hours: hours - 1, minutes: 59, seconds: 59 };
        }
        return { ...timer, minutes: minutes - 1, seconds: 59 };
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const colorHandler = {
    background: () => {
      if (theme === "classic") {
        return "bg-white";
      } else if (theme === "darkgray") {
        return "bg-gray-500";
      }
    },
    secondaryBackground: () => {
      if (theme === "classic") {
        return "bg-[#6589C6]";
      } else if (theme === "darkgray") {
        return "bg-slate-100";
      }
    },
    textColor: () => {
      if (theme === "classic") {
        return "text-gray-400";
      } else if (theme === "darkgray") {
        return "text-white";
      }
    },
  };
  return (
    <header>
      <div
        className={`${colorHandler.background()} h-14 text-gray-500 border-b w-full shadow-sm flex items-center justify-between pr-2`}
      >
        <div className="font-semibold text-[20px] bg-orange-500 h-14 text-white flex items-center px-4">
          LOGO
        </div>
        <div
          className={`font-semibold flex items-center space-x-2 ${colorHandler.textColor()}`}
        >
          <div> Sunil Charan</div>
          <i className="fa-solid fa-circle-user text-3xl text-gray-400"></i>
        </div>
      </div>
      <div
        className={`bg-[#6589C6] text-white font-semibold h-12 flex items-center justify-between px-4`}
      >
        <div>Assessment Name : React Technology</div>
        <div className="text-sm">
          Time Left :{" "}
          <span className="text-lg">
            {time.hours.toString().padStart(2, "0")}:
            {time.minutes.toString().padStart(2, "0")}:
            {time.seconds.toString().padStart(2, "0")}
          </span>
        </div>
      </div>
    </header>
  );
};

export default Header;
