import React, { useEffect, useState } from "react";
import Button from "../../Button/Button";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import Questions from "../../TestPlayer/TestPlayer";
import { json } from "../../../service/json";

export interface PaginationProps {
  questionNumber: number;
  bookmark: boolean;
}
const ClassicGray = () => {
  const [indexing, setIndexing] = useState<number>(1);
  const [textSize, setTextSize] = useState<string>("normal");
  const [pagination, setPagination] = useState<PaginationProps[]>([]);
  const [answers, setAswers] = useState<any>([]);

  useEffect(() => {
    const res = json.map((question: any, index: number) => {
      return {
        questionNumber: question.questionNo,
        question: question.question,
        answer: null,
      };
    });

    setAswers(res);
    console.log(res, "res");
  }, []);

  useEffect(() => {
    transformedPaginate();
  }, []);

  const transformedPaginate = () => {
    const transformedArray = Array.from(
      { length: json.length },
      (_, i) => i + 1
    ).map((item) => {
      return { questionNumber: item, bookmark: false };
    });
    setPagination(transformedArray);
  };

  const onNextClick = (arg?: number) => {
    if (arg ? arg : indexing !== json.length) {
      setIndexing(arg ? arg + 1 : indexing + 1);
    }
  };
  const onPrevClick = (arg?: number) => {
    if (arg ? arg : indexing > 1) {
      setIndexing(arg ? arg - 1 : indexing - 1);
    }
  };
  const questionNoclick = (arg: number) => {
    setIndexing(arg + 1);
  };
  const fontHandler = (arg: string) => {
    setTextSize(arg);
  };
  const onBookMark = (questionNo: number) => {
    const updatedState = pagination.map((item: PaginationProps) => {
      if (item.questionNumber === questionNo) {
        return { ...item, bookmark: !item.bookmark };
      }
      return item;
    });
    setPagination(updatedState);
  };
  const selectedAnswers = (
    questionNo: number,
    question: string,
    anwer: string
  ) => {
    console.log(questionNo, question, anwer);
  };
  return (
    <div className="transition-all duration-300 ease-in-out h-full">
      <Header />
      <div className="relative sm:min-h-[300px] p-4">
        <Questions
          indexing={indexing}
          json={json}
          textSize={textSize}
          selectAnswer={selectedAnswers}
          answers={answers}
        />
        <div className="sm:absolute right-4 bottom-4">
          <div className="flex space-x-2 sm:mt-0 mt-2 justify-end">
            <Button onClick={() => onBookMark(indexing)}>
              <i className="fa-solid fa-bookmark pr-1"></i>
              {pagination.map((item: PaginationProps, _index: number) => {
                if (item.questionNumber === indexing) {
                  return item.bookmark === true ? "BOOKMARKED" : "BOOKMARK";
                }
              })}
            </Button>
            {indexing !== 1 && <Button label={"PREV"} onClick={onPrevClick} />}
            {indexing !== json.length && (
              <Button label={"NEXT"} onClick={onNextClick} />
            )}
            {indexing === json.length && (
              <Button label={"SUBMIT"} onClick={onNextClick} />
            )}
          </div>
        </div>
      </div>

      <Footer
        onClickPage={questionNoclick}
        indexing={indexing}
        handleFontSize={fontHandler}
        onNextClick={onNextClick}
        onPrevClick={onPrevClick}
        pagination={pagination}
      />
    </div>
  );
};

export default ClassicGray;
