import React, { useEffect, useState } from "react";
import dragLog from "../../assets/draglog.png";

const Questions = (props: any) => {
  const { indexing, json, textSize, selectAnswer } = props;
  const [pic, setPic] = useState<any>(dragLog);
  const [state, setState] = useState<string>("Drag here...");
  const [myAnswers, setMyAnswers] = useState(json);

  const handleDragStart = (
    event: React.DragEvent<HTMLDivElement>,
    content: string
  ) => {
    event.dataTransfer.setData("text/plain", content);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const content = event.dataTransfer.getData("text/plain");
    setState(content);
  };

  const handleImageDragStart = (
    event: React.DragEvent<HTMLDivElement>,
    content: string
  ) => {
    event.dataTransfer.setData("img/plain", content);
  };

  const handleImageDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const content = event.dataTransfer.getData("img/plain");
    setPic(content);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const blankQuestionHandler = (question: string) => {
    const elements: any = [];
    question.split(" ").forEach((part: string, index: number) => {
      if (part !== "(blank)") {
        elements.push(part);
        elements.push(" ");
      } else {
        elements.push(
          <span key={index}>
            <input className="border-b-2 outline-none w-[150px]" />
          </span>
        );
        elements.push("  ");
      }
    });
    return <div>{elements}</div>;
  };

  const dropDownQuestionHandler = (question: string, options: any) => {
    const elements: any = [];
    question.split(" ").forEach((part: string, index: number) => {
      if (part !== "(blank)") {
        elements.push(part);
        elements.push(" ");
      } else {
        elements.push(
          <span key={index} className="">
            <select
              style={{ WebkitAppearance: "none" }}
              className="border-b-2 outline-none w-[150px] relative px-2"
            >
              <option value={""}></option>
              {options.map((item: any, index: number) => {
                return <option key={index}>{item.name}</option>;
              })}
            </select>
          </span>
        );
        elements.push("  ");
      }
    });
    return <div>{elements}</div>;
  };

  const dragQuestionHandler = (question: string) => {
    return (
      <div className="w-full flex items-center space-x-2">
        <div className="font-semibold text-black">{question} </div>
        <div
          className="h-10 text-[blue] font-semibold border min-w-[200px] shadow-sm cursor-pointer flex items-center justify-center rounded-sm"
          onDrop={handleDrop}
          onDragOver={handleDragOver}
        >
          {state}
        </div>
      </div>
    );
  };
  const dragImageQuestionHandler = (question: string) => {
    return (
      <div className="w-full flex  space-x-2">
        <div className="font-semibold text-black">{question} </div>
        <img
          alt="answer-img"
          onDrop={handleImageDrop}
          onDragOver={handleDragOver}
          src={pic}
          className="border p-2 h-40 w-40 text-left shadow-md rounded-sm my-2 "
        />
      </div>
    );
  };
  const answerHandling = (e: any, questionNo: number, qindex: number) => {
    // const updatedItems = [...myAnswers]; // Create a copy of the original array
    // updatedItems[qindex] = { ...updatedItems[qindex], answers: e.target.value }; // Update the specific item
    // console.log(updatedItems, "updatedItems");
    // setMyAnswers(updatedItems); // Set the state with the updated array
  };
  const handlingQuestionTypes = (item: any, qindex: number) => {
    switch (item.type) {
      case "mcq":
        return (
          <>
            <div className="flex items-center space-x-4 mb-4">
              <div className="bg-sky-500 px-3 text-white font-semibold rounded-sm">
                {item.questionNo}
              </div>
              <div className="font-semibold text-black">{item.question}</div>
            </div>
            <div className="mx-12">
              {item.options.map((option: any, index: number) => {
                return (
                  <div
                    key={index}
                    className="flex items-center space-x-4 text-gray-800 mb-3"
                  >
                    <div className="flex items-center mt-1">
                      <input type="radio" />
                    </div>
                    <div className=" flex items-center">{option}</div>
                  </div>
                );
              })}
            </div>
            <div className="text-sm mt-2 text-gray-500 mx-12">
              <span className="font-semibold">Note :</span> Please select any
              one of the option.
            </div>
          </>
        );

      case "mcq-response":
        return (
          <>
            <div className="flex items-center space-x-4 mb-4">
              <div className="bg-sky-500 px-3 text-white font-semibold rounded-sm">
                {item.questionNo}
              </div>
              <div className="font-semibold text-black">{item.question}</div>
            </div>
            <div className="mx-12">
              {item.options.map((option: any, index: number) => {
                return (
                  <div
                    key={index}
                    className="flex items-center space-x-4 text-gray-800 mb-3"
                  >
                    <div className="flex items-center mt-1">
                      <input type="checkbox" />
                    </div>
                    <div className=" flex items-center">{option}</div>
                  </div>
                );
              })}
            </div>
            <div className="text-sm mt-2 text-gray-500 mx-12">
              <span className="font-semibold">Note :</span> You can select
              multiple options.
            </div>
          </>
        );

      case "blank":
        return (
          <>
            <div className="flex items-center space-x-4 mb-4">
              <div className="bg-sky-500 px-3 text-white font-semibold rounded-sm">
                {item.questionNo}
              </div>
              <div className="font-semibold text-black">
                {blankQuestionHandler(item.question)}
              </div>
            </div>
            <div className="text-sm mt-2 text-gray-500 mx-12">
              <span className="font-semibold">Note : </span>Please enter your
              answer in the above blank space.
            </div>
          </>
        );
      case "blackWithDropDown":
        return (
          <>
            <div className="flex items-center space-x-4 mb-4 ">
              <div className="bg-sky-500 px-3 text-white font-semibold rounded-sm">
                {item.questionNo}
              </div>
              <div className="font-semibold text-black">
                {dropDownQuestionHandler(item.question, item.options)}
              </div>
            </div>
            <div className="text-sm mt-2 text-gray-500 mx-12">
              <span className="font-semibold">Note :</span> Please click on the
              blank space to get the list of options
            </div>
          </>
        );
      case "bool":
        return (
          <>
            <div className="flex items-center space-x-4 mb-4">
              <div className="bg-sky-500 px-3 text-white font-semibold rounded-sm">
                {item.questionNo}
              </div>
              <div className="font-semibold text-black">{item.question}</div>
            </div>
            <div className="mx-12">
              {item.options.map((option: any, index: number) => {
                return (
                  <div
                    key={index}
                    className="flex items-center space-x-4 text-gray-800 mb-3"
                  >
                    <div className=" flex items-center mt-1">
                      <input type="radio" />
                    </div>
                    <div className=" flex items-center">{option}</div>
                  </div>
                );
              })}
            </div>{" "}
          </>
        );
      case "drag_and_drop":
        return (
          <>
            <div className="flex items-center space-x-4 mb-4">
              <div className="bg-sky-500 px-3 text-white font-semibold rounded-sm">
                {item.questionNo}
              </div>
              {dragQuestionHandler(item.question)}
            </div>
            <div className="mx-12 w-fit text-black cursor-pointer">
              {item.options.map((item: any, index: number) => {
                return (
                  <div
                    draggable
                    onDragStart={(e) => handleDragStart(e, item.name)}
                    key={index}
                    className="border p-2 min-w-[200px] text-left shadow-md rounded-sm my-2 bg-sky-100 "
                  >
                    {item.name}
                  </div>
                );
              })}
              <div className="text-sm mt-2 text-gray-500">
                <span className="font-semibold">Note :</span> Drag and drop the
                answer to the above box.
              </div>
            </div>{" "}
          </>
        );
      case "drag_and_drop_image":
        return (
          <>
            <div className="flex  space-x-4 mb-4">
              <div className="bg-sky-500 px-3 text-white font-semibold rounded-sm h-fit">
                {item.questionNo}
              </div>
              {dragImageQuestionHandler(item.question)}
            </div>
            <div className="mx-12 w-fit text-black cursor-pointer flex space-x-4">
              {item.options.map((item: any, index: number) => {
                return (
                  <img
                    alt="answer"
                    key={index}
                    draggable
                    onDragStart={(e) => handleImageDragStart(e, item.value)}
                    src={item.value}
                    className="border p-2 h-40 w-40 text-left shadow-md rounded-sm my-2 "
                  />
                );
              })}
            </div>{" "}
            <div className="text-sm mt-2 text-gray-500">
              <span className="font-semibold">Note :</span> Drag and drop the
              answer to the above box.
            </div>
          </>
        );
    }
  };

  return (
    <div>
      {myAnswers
        .filter((_q: any, index: number) => index + 1 === indexing)
        .map((item: any, index: number) => {
          return (
            <div
              key={index}
              className={`${
                textSize === `normal`
                  ? `fontNormal`
                  : textSize === `medium`
                  ? `fontMedium`
                  : `fontLarge`
              }`}
            >
              {handlingQuestionTypes(item, index)}
            </div>
          );
        })}
    </div>
  );
};

export default Questions;
