import React, { useState } from "react";
import Button from "../Button/Button";
import { PaginationProps } from "../Layout/ClassicGray/ClassicGray";
interface FooterProps {
  onClickPage: (arg: number) => void;
  handleFontSize: (arg: string) => void;
  indexing: number;
  pagination: PaginationProps[];
  onNextClick: (arg: number) => void;
  onPrevClick: (arg: number) => void;
}

const Footer = (props: FooterProps) => {
  const {
    onClickPage,
    handleFontSize,
    indexing,
    pagination,
    onNextClick,
    onPrevClick,
  } = props;
  const [paginationShow, setPagenationShow] = useState(true);
  const [showFonts, setShowFonts] = useState(false);

  return (
    <div>
      <div
        onClick={() => setPagenationShow(!paginationShow)}
        className={`absolute ${
          paginationShow ? "bottom-24" : "bottom-10"
        } left-0 right-0 ml-auto mr-auto w-fit bg-[#f2400a] px-2 py-1 rounded-tl-md rounded-tr-md border-2 border-gray-300 cursor-pointer transition-all duration-300 ease-in-out`}
      >
        <div className="text-sm text-white">
          <span>
            {paginationShow ? (
              <i className="fa-solid fa-angles-down px-1"></i>
            ) : (
              <i className="fa-solid fa-angles-up px-1"></i>
            )}
          </span>{" "}
          Question Navigation
        </div>
      </div>
      <div
        className={`absolute bottom-0 w-full transition-all duration-300 ease-in-out`}
      >
        <div
          className={`${
            paginationShow ? "block" : "hidden"
          } relative flex items-center space-x-1 bg-gray-300 p-3`}
        >
          <div className="font-semibold"> Question No:</div>
          {pagination.map((item: PaginationProps, index: number) => {
            return (
              <div key={index} className="relative">
                <div
                  onClick={() => onClickPage?.(index)}
                  className={`${
                    item.questionNumber === indexing
                      ? "bg-sky-600"
                      : "bg-[#067308]"
                  }  px-3 py-1 font-semibold  w-fit rounded-sm cursor-pointer text-white`}
                >
                  {item.questionNumber}
                </div>
                {item.bookmark && (
                  <i className="absolute top-[-3px] left-0 fa-solid fa-bookmark text-sm text-[orange]"></i>
                )}
              </div>
            );
          })}
        </div>

        <div className="bg-gray-500 h-10 flex items-center justify-between px-4">
          <div className="flex items-center space-x-2 text-sm">
            <div
              style={{ width: "200px" }}
              className="bg-blue-500 h-5 border border-gray-400 rounded-sm text-[10px] text-white  flex item"
            >
              <div
                style={{
                  width: `${(100 / pagination.length) * indexing * 2}px`,
                }}
                className="bg-orange-500  transition-all duration-300 ease-in-out"
              ></div>
            </div>{" "}
            <div className="text-white">
              {indexing}/{pagination.length}
            </div>
            <div
              onClick={() => setShowFonts(!showFonts)}
              className="relative font-bold text-md text-white cursor-pointer flex items-center pl-4"
            >
              <span className="border px-1 mr-1">
                {" "}
                <i className="fa-solid fa-font"></i>
              </span>
              <div>Font</div>
              {showFonts && (
                <div className="absolute flex items-center bg-white border border-blue-500 h-10 top-[-50px] rounded-sm">
                  <div
                    className="border-r h-full px-4 flex items-center"
                    onClick={() => handleFontSize?.("normal")}
                  >
                    <i className="fa-solid fa-font text-black"></i>
                  </div>
                  <div
                    className="border-r h-full px-4 flex items-center"
                    onClick={() => handleFontSize?.("medium")}
                  >
                    <i className="fa-solid fa-font text-black text-[18px]"></i>
                  </div>
                  <div
                    className="border-r h-full px-4 flex items-center"
                    onClick={() => handleFontSize?.("large")}
                  >
                    <i className="fa-solid fa-font text-black text-[22px]"></i>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center space-x-2">
            {indexing !== 1 && (
              <Button label={"PREV"} onClick={() => onPrevClick?.(indexing)} />
            )}
            {indexing !== pagination.length && (
              <Button label={"NEXT"} onClick={() => onNextClick?.(indexing)} />
            )}

            <Button
              label={"SUBMIT"}
              onClick={() => {
                return null;
              }}
            />
          </div>
        </div>
      </div>{" "}
    </div>
  );
};

export default Footer;
