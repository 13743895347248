import React from "react";
import ClassicGray from "./components/Layout/ClassicGray/ClassicGray";

const App = () => {
  return (
    <div>
      <ClassicGray />
    </div>
  );
};

export default App;
